import React, { Component, useState, useEffect } from 'react';
// import { string } from 'prop-types';
// import classNames from 'classnames';
// import {pushToPath} from '../../util/urlHelpers';
// import { FormattedMessage } from '../../util/reactIntl';
// import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { createImageVariantConfig } from '../../util/sdkLoader';
import config from '../../config';
import { NamedLink, ListingCard, Button } from '../../components';
import { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import css from './SectionListings.module.css';
import dynamic from 'next/dynamic';
import Slider from 'react-slick';
import { pushToPath } from '../../util/urlHelpers';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const Carousel2 =
  typeof window !== 'undefined'
    ? require('@brainhubeu/react-carousel').default
    : dynamic(() => import('@brainhubeu/react-carousel'), {
      ssr: false,
    });

function SectionListings(props) {
  const { searchParam, title } = props;
  const [mostWantedListings, setMostWantedListings] = useState([]);

  const isServer = typeof window === 'undefined';
  useEffect(() => {
    const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
    const aspectRatio = aspectHeight / aspectWidth;
    const profileAspectRatio = 1 / 1;
    const searchExtraParams = searchParam ? searchParam : {};

    sdk.listings

      .query({
        ...searchExtraParams,
        include: ['author', 'images', 'author.profileImage'],
        'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x', 'variants.square-small', 'variants.square-small2x'],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        ...createImageVariantConfig('profileImage', 200, profileAspectRatio),  // Add profile image variant
        ...createImageVariantConfig('profileImage-2x', 400, profileAspectRatio),  // Add profile image 2x variant
        'limit.images': 1,
      })
      .then(res => {
        const listings = res.data.data;
        const images = res.data.included.filter(i => {
          return i.type === 'image';
        });

        const users = res.data.included.filter(i => {
          return i.type === 'user';
        });
        listings.forEach(l => {
          const imageId = l.relationships.images.data[0]?.id.uuid;
          const authorId = l.relationships.author.data?.id.uuid;
          if (imageId) {
            const luckyImage = images.find(i => {
              return i.id.uuid === imageId;
            });
            l.images = [luckyImage];
          }

          if (authorId) {
            const author = users.find(u => {
              return u.id.uuid === authorId;
            });

            const authorImageId = author.relationships.profileImage.data?.id.uuid;
            const authorImage = images.find(i => {
              return i.id.uuid === authorImageId;
            });

            l.author = author;
            l.author.profileImage = authorImage;
          }
        });
        setMostWantedListings(res.data.data.slice(0, 5));
      })
      .catch(e => {
        return console.log(e);
      });
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      console.log('undefined');
    } else {
      require('slick-carousel/slick/slick-theme.css');
      require('slick-carousel/slick/slick.css');
    }
  }, []);

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const slidesToShow = typeof window !== 'undefined' && window.innerWidth < 768 ? 1.1 : 4;
  const showDots = typeof window !== 'undefined' && window.innerWidth < 768 ? true : false;

  const settings = {
    dots: showDots,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className={css.carouselWrapper}>
      <h2 className={css.title}>{props.title}</h2>
      {mostWantedListings.length > 0 ? (
        <>
          <Slider {...settings}>
            {mostWantedListings.map(l => {
              return (
                <div className={css.listingWrapper}>
                  <ListingCard
                    className={css.listingCard}
                    key={l.id.uuid}
                    listing={l}
                    renderSizes={cardRenderSizes}
                  />
                </div>
              );
            })}
          </Slider>

          <Button
            className={css.searchButton}
            onClick={() => {
              if (title === 'Angebote von Gastgeber:innen entdecken') {
                pushToPath(
                  '/s?address=Hannover%2C%20Niedersachsen%2C%20Deutschland&bounds=53.26433294%2C11.017876%2C51.47692237%2C8.505124&pub_category=offeringPlace&mapSearch=true'
                );
              } else {
                pushToPath(
                  '/s?address=Hannover%2C%20Niedersachsen%2C%20Deutschland&bounds=53.26433294%2C11.017876%2C51.47692237%2C8.505124&pub_category=lookingForPlace&mapSearch=true'
                );
              }
            }}
          // onClick={() => {
          //   if (title === 'Angebote von Gastgeber:innen entdecken') {
          //     pushToPath(
          //       '/s?address=Nürnberg%2C%20Bayern%2C%20Deutschland&bounds=49.56558233%2C11.23434083%2C49.33584577%2C10.94047783&mapSearch=true'
          //     );
          //   } else {
          //     pushToPath(
          //       '/s?address=Nürnberg%2C%20Bayern%2C%20Deutschland&bounds=49.56558233%2C11.23434083%2C49.33584577%2C10.94047783&mapSearch=true'
          //     );
          //   }
          // }}
          >
            Alle anzeigen
          </Button>
        </>
      ) : (
        <center>
          <h2>Noch keine Einträge 😔</h2>
        </center>
      )}
    </div>
  );
}

export default SectionListings;
